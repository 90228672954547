import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout/Layout';
import { SeoTags } from '../components/SeoTags';

export default function NotFound({ data }) {
  const description = data.site.siteMetadata.description;

  return (
    <Layout description={description} centerContent>
      <SeoTags
        title="Nie znaleziono wpisu"
        description={description}
      />
      <h1>Nie znaleziono wpisu</h1>
      <p>Przykro mi, ale niestety nie ma takiego wpisu.</p>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`
